const Hello = ({setWatchList, watchList}) => {
    return (
        <>
            <div className="notFound">
                <h1>Hello</h1>
            </div>
        </>
    )
}

export default Hello;