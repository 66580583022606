import React from 'react';
import MovieDetailsComponent from '../Components/MovieDetails'


const MovieDetails = () => {
    return (
        <>
            <MovieDetailsComponent/>

        </>
    )
}

export default MovieDetails;