import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import MovieCard from '../Components/MovieCard';
import SubBanner from '../Components/SubBanner';
import NoData from '../Components/Search/noData';

import Pagination from '../Components/Pagination';
import {fetchMovieSearch} from "../utils/moviedb";
import LoadMoreAnimation from "../Components/LoadingAnimations/LoadMoreAnimation";

const SearchPage = () => {
    const {query} = useParams();

    const [movieData, setMovieData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [totalPages, setTotalPages] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setIsLoaded(false);
        fetchSearchResults().then(() => setIsLoaded(true));
    }, [currentPage]);

    const fetchSearchResults = async () => {

        const data = await fetchMovieSearch(query, currentPage);

        const uniqueArray = [...movieData, ...data.results].filter((item, index, self) => {
            return self.indexOf(item) === index;
        });
        setMovieData(uniqueArray);
        setTotalPages(data.total_pages);
    };

    const nextPage = () => {
        setCurrentPage(pageNumber => pageNumber + 1);
    }


    return (
        <>
            {/*<SubBanner title={'Search Results'} pathName={'Search'}/>*/}
                <section className='results-sec'>
                    <div className='container'>
                        <div className='section-title'>
                            {/*<h5 className='sub-title'>ONLINE STREAMING</h5>*/}
                            <h2 className='title'>{query}'s Related Results</h2>
                        </div>

                        {movieData.length ? (
                            <div
                                className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 gap-6'>
                                {movieData.map((movie) => (
                                    <MovieCard movie={movie} key={movie.id}/>))}
                            </div>
                        ) : (<NoData/>)}
                        {!isLoaded && <LoadMoreAnimation/>}

                        {isLoaded && currentPage < totalPages &&
                            <div className='text-center'>
                                <button
                                    onClick={nextPage}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                                    Load More....
                                </button>
                            </div>
                        }
                    </div>
                </section>

        </>
    )
        ;
};

export default SearchPage;