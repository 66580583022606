import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import './style.css';
import {convertMinutesToHours, fetchMovieDetailsAll, image500} from "../../utils/moviedb";
import MovieCard from "../MovieCard";
import PosterWithPlaceholder from "../MovieCard/PosterWithPlaceholder";
import PosterCarousel from "../MovieDetailsContents/PosterCarousel";
import BackdropCarousel from "../MovieDetailsContents/BackdropCarousel";
import CastCarousel from "../MovieDetailsContents/CastCarousel";
import SimilarMovies from "../MovieDetailsContents/SimilarMovies";

const MovieDetailsComponent = () => {
    const {id} = useParams();
    const [movie, setMovie] = useState('');
    let lastWord = movie ? movie.title.split(' ').pop() : '';


    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        setIsLoaded(false)

        getMovieDetails(id).then(() => {
            setIsLoaded(true)
        })

    }, [id]);

    const getMovieDetails = async (id) => {
        const movieDetailsData = await fetchMovieDetailsAll(id)
        setMovie(movieDetailsData)
    }


    return (
        <header className='page-header movie-details-header'>

            <div className='container'>
                {isLoaded ? (
                    <div>
                        <div className='movie-details'>
                            <div className='movie-poster'>
                                <PosterWithPlaceholder src={image500(movie.poster_path)}/>
                            </div>
                            <div className='details-content'>

                                <h2 className='title'>{movie.title.split(' ').slice(0, -1).join(' ')}
                                    <span>{lastWord}</span></h2>
                                <div className='banner-meta'>
                                    <ul>
                                        <li className='vid'>
                                            <span className='type'>{movie.original_language}</span>
                                            <span className='quality'>HD</span>
                                        </li>
                                        <li className='category'>

                                            {movie.genres.map((genre, index) => (
                                                <Genre key={genre.id} genre={genre.name}
                                                       isLast={index === movie.genres.length - 1}/>
                                            ))}

                                        </li>
                                        <li className='time'>
                                    <span>
                                    <i className='ri-calendar-2-line'></i>
                                        {movie.release_date}
                            </span>
                                            <span>
                      <i className='ri-time-line'></i>
                                                {convertMinutesToHours(movie.runtime)}

                    </span>
                                        </li>
                                    </ul>
                                </div>
                                <p className='desc'>{movie.overview}</p>
                                <Link to={`/download/${movie.id}/${movie.title}`}
                                      className='btn watch-btn'
                                      target='_blank'
                                      rel='noreferrer'>
                                    <i className="ri-download-line"></i>
                                    Download
                                </Link>

                            </div>
                        </div>
                        <div className="mx-10">
                            <PosterCarousel posters={movie.images.posters}/>
                            <BackdropCarousel backdrops={movie.images.backdrops}/>
                            <CastCarousel casts={movie.credits.cast}/>
                            <SimilarMovies id={id} movieTitle={movie.title}/>
                        </div>
                    </div>

                ) : null}
            </div>
        </header>);
};

export default MovieDetailsComponent;


export const Genre = ({genre, isLast}) => {
    return (
        <>
            <span>{genre}</span>
            {!isLast && <span>&nbsp;•&nbsp;</span>}
        </>
    )
}