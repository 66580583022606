import React from 'react'
import MovieCard from '../Components/MovieCard';
import SubBanner from '../Components/SubBanner';

import NoData from '../Components/Search/noData';

const Favourites = ({watchList, setWatchList}) => {
    return (
        <>
            <SubBanner title={'My Favourites'} pathName={'Favourites'}/>

            <section className='results-sec'>
                <div className='container'>
                    <div className='row movies-grid'>
                        {
                            watchList.length
                                ?
                                watchList.map(movie => (
                                    <MovieCard movie={movie} key={movie.imdbID} setWatchList={setWatchList}
                                               watchList={watchList}/>
                                ))
                                :
                                <NoData/>
                        }
                    </div>
                </div>
            </section>

        </>
    )
}

export default Favourites;