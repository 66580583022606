import React, {useEffect, useRef, useState} from 'react';
import MovieCard from '../MovieCard';
import './style.css';
import {
    fetchDailyTrendingMovies,
    fetchNowPlayingMovies,
    fetchPopularMovies, fetchTopRatedMovies, fetchUpcomingMovies,
    fetchWeeklyTrendingMovies
} from "../../utils/moviedb";
import LoadMoreAnimation from "../LoadingAnimations/LoadMoreAnimation";

const TopMovies = () => {

    const [filterTag, setFilterTag] = useState('Daily Trending');

    const [movieData, setMovieData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setIsLoaded(false);
        getMovieData(filterTag, currentPage).then(() => setIsLoaded(true));
    }, [currentPage]);

    useEffect(() => {
        setMovieData([])
        setIsLoaded(false);
        if (currentPage !== 1) {
            setCurrentPage(1);
        } else {
            getMovieData(filterTag, currentPage).then(() => setIsLoaded(true));
        }
    }, [filterTag]);

    const nextPage = () => {
        setCurrentPage(pageNumber => pageNumber + 1);
    }


    const getMovieData = async (filterTag, page = 1) => {
        let data;
        switch (filterTag) {
            case 'Daily Trending':
                data = await fetchDailyTrendingMovies(page);
                break;
            case 'Now Playing':
                data = await fetchNowPlayingMovies(page);
                break;
            case 'Weekly Trending':
                data = await fetchWeeklyTrendingMovies(page);
                break;
            case 'Popular':
                data = await fetchPopularMovies(page);
                break;
            case 'Top Rated':
                data = await fetchTopRatedMovies(page);
                break;
            case 'Upcoming':
                data = await fetchUpcomingMovies(page);
                break;
            default:
                data = await fetchDailyTrendingMovies(page);
        }


        // add delay for 2 seconds
        // await new Promise(r => setTimeout(r, 2000));

        if (page === 1)
            setMovieData(data.results);
        else {
            const uniqueArray = [...movieData, ...data.results].filter((item, index, self) => {
                return self.indexOf(item) === index;
            });
            setMovieData(uniqueArray);
        }
    }

    const handleFilterTag = (e) => {
        setFilterTag(e.target.textContent);
    };


    // const handleInfiniteScroll = async () => {
    //     if (window.innerHeight + document.documentElement.scrollTop + 200 >= document.documentElement.scrollHeight) {
    //         nextPage();
    //     }
    // };
    //
    // useEffect(() => {
    //     window.addEventListener("scroll", handleInfiniteScroll);
    //     return () => window.removeEventListener("scroll", handleInfiniteScroll);
    // })


    return (
        <section className='new-sec top-rated-sec'>
            <div className='container'>
                <div className='btns-div categories-btns'>
                    <button
                        className={
                            filterTag === 'Daily Trending'
                                ? 'btn category-btn active'
                                : 'btn category-btn'
                        }
                        onClick={(e) => handleFilterTag(e)}>
                        Daily Trending
                    </button>
                    <button
                        className={
                            filterTag === 'Weekly Trending'
                                ? 'btn category-btn active'
                                : 'btn category-btn'
                        }
                        onClick={(e) => handleFilterTag(e)}>
                        Weekly Trending
                    </button>
                    <button
                        className={
                            filterTag === 'Now Playing'
                                ? 'btn category-btn active'
                                : 'btn category-btn'
                        }
                        onClick={(e) => handleFilterTag(e)}>
                        Now Playing
                    </button>
                    <button
                        className={
                            filterTag === 'Popular'
                                ? 'btn category-btn active'
                                : 'btn category-btn'
                        }
                        onClick={(e) => handleFilterTag(e)}>
                        Popular
                    </button>
                    <button
                        className={
                            filterTag === 'Top Rated'
                                ? 'btn category-btn active'
                                : 'btn category-btn'
                        }
                        onClick={(e) => handleFilterTag(e)}>
                        Top Rated
                    </button>
                    <button
                        className={
                            filterTag === 'Upcoming'
                                ? 'btn category-btn active'
                                : 'btn category-btn'
                        }
                        onClick={(e) => handleFilterTag(e)}>
                        Upcoming
                    </button>
                </div>
                <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 gap-6'>
                    {
                        movieData.map(movie => (
                            <MovieCard movie={movie} key={movie.id}/>
                        ))
                    }
                </div>
                {!isLoaded && <LoadMoreAnimation/>}

                {isLoaded &&
                    <div className='text-center'>
                        <button
                            onClick={nextPage}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                            Load More....
                        </button>
                    </div>
                }

            </div>


        </section>
    );
};

export default TopMovies;
