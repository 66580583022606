import React from 'react';
import {image185} from "../../utils/moviedb";
import PosterWithPlaceholder from "./PosterWithPlaceholder";
import {Link} from "react-router-dom";

const MovieCard = ({movie}) => {
    return (<div
            className="w-44 m-auto rounded-lg mb-10 group relative overflow-hidden transition-transform duration-300 transform hover:scale-110 ">

            <Link to={`/movie/${movie.id}`} className="w-full h-full">
                <div className="mx-2 relative">
                    {movie.vote_average !== 0 &&
                        // <div className="absolute left-0 bg-yellow-400 rounded-tl-lg rounded-br-lg">
                        <div className="absolute right-0 bg-yellow-400 rounded-tr-lg rounded-bl-lg">
                            <p className="text-black text-xs font-semibold text-center py-1 px-2.5">
                                {movie.vote_average.toFixed(2)}
                            </p>
                        </div>
                    }
                    <PosterWithPlaceholder src={image185(movie.poster_path)}/>
                    <div
                        className="rounded-b-lg absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-2">
                        <p className="text-white text-sm font-semibold text-center tracking-wide drop-shadow-lg">
                            {movie.title.length > 40 ? movie.title.split('').slice(0, 20).join('') + '...' : movie.title}
                        </p>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default MovieCard;