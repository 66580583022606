import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import React from 'react';
import {image185} from "../../utils/moviedb";
import genericPlaceholder from "../../images/placeholder.png";
import malePlaceholder from "../../images/male_placeholder.png";
import femalePlaceholder from "../../images/female_placeholder.png";
import Image from 'rc-image';

const responsive = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 8,
        slidesToSlide: 4,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 1,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 2,
        partialVisibilityGutter: 30
    }
}

const CastCarousel = ({casts}) => {

    return (
        <div className="mt-10">
            <Carousel
                responsive={responsive}
                itemClass={"p-1"}
                swipeable
                draggable
            >
                {casts.map((cast, index) => (
                        <div key={index}>

                            {/*<Image src={cast.gender == 1 ? femalePlaceholder : malePlaceholder}*/}
                            <Image src={image185(cast.profile_path)}
                                   alt="movie-poster"
                                   className="rounded-3xl aspect-auto  border border-yellow-100"
                                   loading="lazy"
                                   preview={false}
                                   fallback={cast.gender === 1 ? femalePlaceholder : cast.gender === 2 ? malePlaceholder : genericPlaceholder}
                                   placeholder={
                                       <img
                                           src={cast.gender === 1 ? femalePlaceholder : cast.gender === 2 ? malePlaceholder : genericPlaceholder}
                                           alt="placeholder"
                                           className="w-full h-full object-cover"/>
                                   }
                            />
                            <p className="text-center text-white text-lg">{cast.name}</p>
                            <p className="text-center text-white text-xs">{cast.character}</p>
                        </div>

                    )
                )}

            </Carousel>
        </div>
    )
}

export default CastCarousel;