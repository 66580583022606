import React, {useEffect, useState} from "react";
import {fetchSimilarMovies} from "../../utils/moviedb";
import MovieCard from "../MovieCard";
import NoData from "../Search/noData";
import LoadMoreAnimation from "../LoadingAnimations/LoadMoreAnimation";

const SimilarMovies = ({id, movieTitle}) => {


    const [movieData, setMovieData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [totalPages, setTotalPages] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setIsLoaded(false);
        getSimilarMovies().then(() => setIsLoaded(true));
    }, [currentPage]);


    const getSimilarMovies = async () => {
        const data = await fetchSimilarMovies(id, currentPage);
        const uniqueArray = [...movieData, ...data.results].filter((item, index, self) => {
            return self.indexOf(item) === index;
        });
        setMovieData(uniqueArray);
        setTotalPages(data.total_pages);
        console.log(data);
    }

    const nextPage = () => {
        setCurrentPage(pageNumber => pageNumber + 1);
    }


    return (
        <>
            <section>
                {movieData.length ? (
                    <div className='container'>

                        <div className='section-title'>
                            {/*<h5 className='sub-title'>ONLINE STREAMING</h5>*/}
                            <h2 className='title'>Movies similar to {movieTitle}</h2>
                        </div>


                        <div
                            className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 gap-6'>
                            {movieData.map((movie) => (
                                <MovieCard movie={movie} key={movie.id}/>))}
                        </div>

                        {!isLoaded && <LoadMoreAnimation/>}

                        {isLoaded && currentPage < totalPages &&
                            <div className='text-center'>
                                <button
                                    onClick={nextPage}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                                    Load More....
                                </button>
                            </div>
                        }
                    </div>
                ) : <></>}
            </section>

        </>
    )

}

export default SimilarMovies;