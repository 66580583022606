import React, {useEffect, useState} from 'react'

import TopMovies from '../Components/TopMovies';

const Home = () => {

    return (
        <>
            <TopMovies/>
        </>
    )
}

export default Home;