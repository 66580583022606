import {Link, useParams} from "react-router-dom";
import {convertMinutesToHours, fetchMovieDetails, image500} from "../utils/moviedb";
import React, {useEffect, useState} from "react";
import PosterWithPlaceholder from "../Components/MovieCard/PosterWithPlaceholder";
import PosterCarousel from "../Components/MovieDetailsContents/PosterCarousel";
import BackdropCarousel from "../Components/MovieDetailsContents/BackdropCarousel";
import CastCarousel from "../Components/MovieDetailsContents/CastCarousel";
import SimilarMovies from "../Components/MovieDetailsContents/SimilarMovies";
import {Genre} from "../Components/MovieDetails";
import DownloadData from "../Components/Download/DownloadData";


const Download = () => {

    const {query, id} = useParams();

    const [movie, setMovie] = useState('');
    let lastWord = movie ? movie.title.split(' ').pop() : '';

    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        setIsLoaded(false)

        getMovieDetails(id).then(() => {
            setIsLoaded(true)
        })

    }, [id]);

    const getMovieDetails = async (id) => {
        const movieDetailsData = await fetchMovieDetails(id)
        setMovie(movieDetailsData)
    }


    return (
        <header className='page-header movie-details-header'>


            <div className='container'>
                {isLoaded ? (
                    <div>
                        <div className='movie-details'>
                            <div className='movie-poster'>
                                <PosterWithPlaceholder src={image500(movie.poster_path)}/>
                            </div>
                            <div className='details-content'>

                                <h2 className='title'>{movie.title.split(' ').slice(0, -1).join(' ')}
                                    <span>{lastWord}</span></h2>
                                <div className='banner-meta'>
                                    <ul>
                                        <li className='vid'>
                                            <span className='type'>{movie.original_language}</span>
                                            <span className='quality'>HD</span>
                                        </li>
                                        <li className='category'>

                                            {movie.genres.map((genre, index) => (
                                                <Genre key={genre.id} genre={genre.name}
                                                       isLast={index === movie.genres.length - 1}/>
                                            ))}

                                        </li>
                                        <li className='time'>
                                    <span>
                                    <i className='ri-calendar-2-line'></i>
                                        {movie.release_date}
                            </span>
                                            <span>
                      <i className='ri-time-line'></i>
                                                {convertMinutesToHours(movie.runtime)}
                    </span>
                                        </li>
                                    </ul>
                                </div>
                                <p className='desc'>{movie.overview}</p>
                            </div>
                        </div>
                        <div className="my-10">
                            <DownloadData query={query} id={id}/>
                        </div>
                    </div>

                ) : null}
            </div>
        </header>);
}

export default Download;