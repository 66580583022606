import React, {useEffect, useState} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import Home from './Pages/Home';
import NotFound from './Pages/NotFound';
import SearchPage from './Pages/SearchPage';
import MovieDetails from './Pages/MovieDetails';
import Search from './Components/Search';
import Favourites from './Pages/Favourites';
import Hello from "./Pages/Hello";
import Download from "./Pages/Download";

function App() {
    const [showSearch, setShowSearch] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [watchList, setWatchList] = useState(JSON.parse(localStorage.getItem('watchList')) || []);

    return (
        <BrowserRouter>
            <Navbar setShowSearch={setShowSearch} watchList={watchList}/>
            <Search showSearch={showSearch} setShowSearch={setShowSearch} setCurrentPage={setCurrentPage}/>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/search/:query'
                       element={<SearchPage/>}/>
                <Route path='/movie/:id' element={<MovieDetails/>}/>
                <Route path='/download/:id/:query' element={<Download/>}/>
                <Route path='/favourites'
                       element={<Favourites watchList={watchList} setWatchList={setWatchList}/>}/>
                <Route path='*' element={<NotFound/>}/>

                <Route path='/hello' element={<Hello/>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
}


export default App;
