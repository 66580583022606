import {fetchDownload} from "../../utils/moviedb";
import React, {useEffect, useState} from "react";
import {Backdrop, CircularProgress, Paper} from "@mui/material";
import {DataGrid, GridRowsProp, GridColDef} from '@mui/x-data-grid';
import axios from 'axios';


const DownloadData = ({id}) => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [download, setDownload] = useState([]);

    useEffect(() => {
        setIsLoaded(false)

        getDownload(id).then(() => {
            setIsLoaded(true)
        })
    }, [id]);


    const getDownload = async (id) => {
        const result = await fetchDownload(id)
        setDownload(result.data)
    }

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };


    const fetchMagnetLink = async (url) => {
        try {
            const response = await axios.get(url);
            return response.data.magnet;
        } catch (error) {
            console.error("Error fetching magnet link:", error);
            return null;
        }
    };

    const handleDownloadClick = async (link) => {
        handleOpen();
        if (link.startsWith("magnet")) {
            window.open(link, "_blank");
        } else {
            const magnet = await fetchMagnetLink(link);
            if (magnet) {
                window.open(magnet, "_blank");
            }
        }
        handleClose();
    };


    const columns = [
            {field: 'type', headerName: 'Type', width: 150},
            {field: 'name', headerName: 'Name', flex: 1},
            {field: 'seeder', headerName: 'Seeder', width: 100},
            {field: 'leecher', headerName: 'Leecher', width: 100},
            {field: 'age', headerName: 'Age', width: 100},
            {field: 'size', headerName: 'Size', width: 100},
            {
                field: 'download',
                headerName: 'Download',
                width: 150,
                renderCell: (params) => (
                    <button onClick={() => handleDownloadClick(params.row.download)}>
                        <p className={"text-black underline"}><i className={"ri-download-line"}></i>Download</p>
                    </button>

                ),
            },
        ]
    ;

    const rows = download.map((item, index) => ({
        id: index,
        type: item.type,
        name: item.name,
        seeder: item.seeder,
        leecher: item.leecher,
        age: item.age,
        size: item.size,
        download: item.magnet === "" ? item.url : item.magnet,

    }));

    return (
        // <div className='container'>
        //     <table className={"border"}>
        //         <thead>
        //         <tr>
        //             <th>Type</th>
        //             <th>Name</th>
        //             <th>Seeder</th>
        //             <th>Leecher</th>
        //             <th>Age</th>
        //             <th>Size</th>
        //         </tr>
        //         </thead>
        //
        //         <tbody>
        //         {isLoaded &&
        //             download.map((download, index) => (
        //                 <tr key={index}>
        //                     <td>{download.type}</td>
        //                     <td>{download.name}</td>
        //                     <td>{download.seeder}</td>
        //                     <td>{download.leecher}</td>
        //                     <td>{download.age}</td>
        //                     <td>{download.size}</td>
        //                 </tr>
        //             ))
        //         }
        //         </tbody>
        //     </table>
        //
        //
        // </div>

        <Paper>

            <Backdrop
                sx={(theme) => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10]}
                // checkboxSelection
                sx={{border: 0}}
            />
        </Paper>
    )
        ;
}
export default DownloadData;