import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import React from 'react';
import {image185, image500} from "../../utils/moviedb";
import PosterWithPlaceholder from "../MovieCard/PosterWithPlaceholder";

const responsive = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 9,
        slidesToSlide: 4,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 2,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 3,
        partialVisibilityGutter: 30
    }
}

const PosterCarousel = ({posters}) => {

    return (
        <div className="mt-10">
            <Carousel
                responsive={responsive}
                itemClass={"p-1"}
                swipeable
                draggable
            >
                {posters.map((poster, index) => (
                    <div key={index} className={"rounded-lg border border-yellow-100"}>
                        <PosterWithPlaceholder src={image185(poster.file_path)}/>
                    </div>
                ))}

            </Carousel>
        </div>
    )
}

export default PosterCarousel;