import axios from 'axios';

const apiBaseUrl = "https://movies.api.dhruvaldhameliya.com/"


const upcomingMoviesEndpoint = (page = 1) => `${apiBaseUrl}upcoming/${page}`;
const topRatedMoviesEndpoint = (page = 1) => `${apiBaseUrl}toprated/${page}`;
const popularMoviesEndpoint = (page = 1) => `${apiBaseUrl}popular/${page}`;
const nowPlayingMoviesEndpoint = (page = 1) => `${apiBaseUrl}nowplaying/${page}`;

const dailyTrendingMoviesEndpoint = (page = 1) => `${apiBaseUrl}trending/day/${page}`;
const weeklyTrendingMoviesEndpoint = (page = 1) => `${apiBaseUrl}trending/week/${page}`;

const movieDetailsAllEndpoint = (id) => `${apiBaseUrl}movie/${id}?append_to_response=videos,credits,images`;
const movieDetailsEndpoint = (id) => `${apiBaseUrl}movie/${id}`;
const movieSearchEndpoint = (query, page = 1) => `${apiBaseUrl}search/${query}/${page}`;

const similarMoviesEndpoint = (id, page = 1) => `${apiBaseUrl}movie/${id}/recommendations?page=${page}`;

const downloadEndpoint = (id) => `${apiBaseUrl}download/?id=${id}`;

const apiCall = async (endpoint, params) => {
    const options = {
        method: 'GET',
        url: endpoint,
        params: params ? params : {}
    };

    try {
        const response = await axios.request(options);
        return response.data;
    } catch (error) {
        console.log('error', error)
        return {}
    }
}

export const fetchUpcomingMovies = async (page) => {
    return apiCall(upcomingMoviesEndpoint(page));
}

export const fetchTopRatedMovies = async (page) => {
    return apiCall(topRatedMoviesEndpoint(page));
}

export const fetchPopularMovies = async (page) => {
    return apiCall(popularMoviesEndpoint(page));
}

export const fetchNowPlayingMovies = async (page) => {
    return apiCall(nowPlayingMoviesEndpoint(page));
}

export const fetchDailyTrendingMovies = async (page) => {
    return apiCall(dailyTrendingMoviesEndpoint(page));
}

export const fetchWeeklyTrendingMovies = async (page) => {
    return apiCall(weeklyTrendingMoviesEndpoint(page));
}


export const image185 = path => `https://images.api.dhruvaldhameliya.com/185${path}`;
export const image500 = path => `https://images.api.dhruvaldhameliya.com/500${path}`;
export const image780 = path => `https://images.api.dhruvaldhameliya.com/780${path}`;
export const imageOriginal = path => `https://images.api.dhruvaldhameliya.com/original${path}`;


export const fetchMovieDetailsAll = async (id) => {
    return apiCall(movieDetailsAllEndpoint(id));
}

export const fetchMovieDetails = async (id) => {
    return apiCall(movieDetailsEndpoint(id));
}

export const fetchMovieSearch = async (query, page) => {
    return apiCall(movieSearchEndpoint(query, page));
}

export const fetchSimilarMovies = async (id, page) => {
    return apiCall(similarMoviesEndpoint(id, page));
}

export const fetchDownload = async (id) => {
    return apiCall(downloadEndpoint(id));
}


export function convertMinutesToHours(minutes) {
    const hours = Math.floor(minutes / 60); // Get the whole hours
    const remainingMinutes = minutes % 60; // Get the remaining minutes
    return `${hours} H : ${remainingMinutes} M`;
}
